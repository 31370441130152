<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-xml`"
        v-model="properties.xml"
        outlined
        dense
        class="required-asterisk"
        data-cy="xslt-xml"
        :label="$lang.labels.xml"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        @input="handleChange('xml', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-xslt`"
        v-model="properties.xslt"
        outlined
        dense
        class="required-asterisk"
        data-cy="xslt-xslt"
        :label="$lang.labels.xslt"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        @input="handleChange('xslt', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        class="required-asterisk"
        data-cy="xslt-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        @input="handleChange('targetObject', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import StepMixin from './StepMixin'
import {  castValueForStep } from '@/utils/helpers'

export default {
  name: 'XSLTStep',
  mixins: [StepMixin],
  props: {
    properties: {
      type: Object,
      default: () => ({})
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(key, value) {
      this.$set(
        this.properties,
        key,
        castValueForStep(key, value, {
          shouldBeString: ['xml', 'xslt', 'targetObject']
        })
      )
    }
  }
}
</script>
